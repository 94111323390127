import React from 'react'

const Modal = ({children}:any) => {
  return (
    <section className='w-full h-full bg-[#00000020] absolute z-30 flex items-center justify-center'>
        {children}
    </section>
  )
}

export default Modal