import React, { useState } from "react";
import banner from "../assets/images/banner1.jpeg";
import ellipse from "../assets/images/ellipse.png";
import frame from "../assets/images/frame.png";
import { Modal, Navbar, Slider } from "../components";
import cta from "../assets/images/CTA.png";
import { PhDrowp } from "../assets/icons";
import blurBg from "../assets/images/Ellipse 5(2).jpg";
import { XMarkIcon } from "@heroicons/react/24/solid";
import AdnocImg from "../assets/images/adnoc.png";
const Header = () => {
  const [modal, setModal] = useState<boolean>();
  return (
    <>
      <section className="w-full  relative">
        <Navbar />
        <div className="w-full h-full flex">
          <div
            className="w-full h-full bg-cover bg-no-repeat px-8 "
            style={{ backgroundImage: `url(${ellipse})` }}
          >
            <div className="space-y-16 px-[2%] py-4 md:py-32">
              <h1 className="text-3xl font-bold">
                Donner un nouveau souffle <br /> à votre moteur avec notre
                partenaire
              </h1>

              {/* <div className='space-x-4'>
              <button className='bg-primary p-4 rounded-md text-white'>Passer une commande</button>
              <button onClick={()=>setModal((prev:any)=>!prev)}>Devenir partenaire</button>
            </div> */}

              <p>
                <img src={AdnocImg} alt="" />
              </p>
              <p className="text-sm">
                Créée en Mars 2011 à Dubai, BSC distribution est une filiale du
                Groupe BSC International. Cette filiale est spécialisée dans la
                commercialisation des lubrifiants. BSC Distribution est le
                distributeur Exclusif des huiles lubrifiantes de marque ADNOC
                VOYAGER en Afrique Subsaharienne. Basé au Burkina Faso à
                Ouagadougou et une agence à bobo-Dioulasso et en Cote d'Ivoire à
                Abidjan.
              </p>
            </div>
          </div>
          <div className="w-full hidden  md:inline-block pt-16 pr-8">
              <img src={frame} className="w-full" alt="hero banner" />
            {/* <Slider/> */}
          </div>
        </div>
      </section>
      {modal && (
        <Modal>
          <section className="bg-white p-4 space-y-4 rounded-md md:min-w-[35%]">
            <div className="flex justify-end">
              <XMarkIcon
                onClick={() => setModal((prev: any) => !prev)}
                className="w-8 h-8"
              />
            </div>
            <div className="flex items-center">
              <label className="w-36">Nom:</label>{" "}
              <input
                className="p-2 outline-none border-2 w-full "
                type="text"
              />
            </div>
            <div className="flex items-center">
              <label className="w-36">Email:</label>{" "}
              <input
                className="p-2 outline-none border-2 w-full "
                type="text"
              />
            </div>
            <div className="flex items-center">
              <label className="w-36">Contact:</label>{" "}
              <input
                className="p-2 outline-none border-2 w-full "
                type="text"
              />
            </div>
            <div className="flex justify-center py-4">
              <button className="bg-primary px-14 py-4 rounded text-white">
                Envoyer
              </button>
            </div>
          </section>
        </Modal>
      )}
    </>
  );
};

export default Header;
