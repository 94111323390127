import axios from 'axios'
export const apiHandler=()=>{
    const url='https://api.optico.dev'

    const $get=async(path:string,id='')=>{
        try {
            const res=await axios.get(`${id?`${url}${path}${id}`:`${url}${path}`}`)
            return res
        } catch (error) {
            console.log(error)
        }
    }
    const $post=async(data:any,path:string)=>{
        try {
            const res=await axios.post(`${url}${path}`,data);
            return res
        } catch (error) {
            console.log(error)
        }
        
    }

    return{$post,$get}
    
}