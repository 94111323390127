import React from 'react'
import gal1 from '../assets/images/galleries/gal1.png'
import gal2 from '../assets/images/galleries/gal2.png'
import gal3 from '../assets/images/galleries/gal3.png'
import gal4 from '../assets/images/galleries/gal4.jpeg'
import gal5 from '../assets/images/galleries/gal5.jpeg'
import gal6 from '../assets/images/galleries/gal6.jpeg'
import gal7 from '../assets/images/galleries/gal7.jpeg'
import gal8 from '../assets/images/galleries/gal8.jpeg'
const Galleries = () => {
  return (
    <section id='products' className="w-full  rounded-xl py-8  space-y-8  bg-gray-100 mt-8">
        <div className='text-center'>
            <h2 className='text-3xl font-extrabold'>Nos Produits</h2>
        </div>
        <section className='w-full grid-cols-2 sm:grid-cols-3 grid md:grid-cols-4 gap-4 p-8 rounded-2xl '>
            <div className='h-64 rounded-xl hover:shadow-xl hover:bg-primary flex justify-center bg-white shadow-sm'>
                <img className='h-full p-2' src={gal6}/>
            </div>
            <div className='h-64 rounded-xl hover:shadow-xl hover:bg-primary flex justify-center bg-white shadow-sm'>
                <img className='h-full p-2' src={gal3}/>
            </div>
            <div className='h-64 rounded-xl hover:shadow-xl hover:bg-primary flex justify-center bg-white shadow-sm'>
                <img className='h-full p-2' src={gal7}/>
            </div>
            <div className='h-64 rounded-xl hover:shadow-xl hover:bg-primary flex justify-center bg-white shadow-sm'>
                <img className='h-full p-2' src={gal1}/>
            </div>

            <div className='h-64 rounded-xl hover:shadow-xl hover:bg-primary flex justify-center bg-white shadow-sm'>
                <img className='h-full p-2' src={gal5}/>
            </div>
            
            <div className='h-64 rounded-xl hover:shadow-xl hover:bg-primary flex justify-center bg-white shadow-sm'>
                <img className='h-full p-2' src={gal8}/>
            </div>
            <div className='h-64 rounded-xl hover:shadow-xl hover:bg-primary flex justify-center bg-white shadow-sm'>
                <img className='h-full p-2' src={gal2}/>
            </div>
            <div className='h-64 rounded-xl hover:shadow-xl hover:bg-primary flex justify-center bg-white shadow-sm'>
                <img className='h-full p-2' src={gal4}/>
            </div>
        </section>
    </section>
  )
}

export default Galleries