import React from 'react'
import { Services, DowloadApp, Foot, Contact, Galleries } from '../sections'
import Header from '../sections/Header'

const Home = () => {
  return (
      <main className='overflow-x-hidden w-full'>
      <Header/>
      <Services/>
      <DowloadApp/>
      <Galleries/>
      <Contact />
      <Foot/>
     </main>
  )
}

export default Home