import React, { useEffect, useState } from "react";
import { apiHandler } from "../core/api";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";

export default function Contact() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBP3cX6cyX8dlr0hiqK-qHhwhTroKaigcw",
  });

  const mapStyles = {
    height: "100%",
    width: "100%",
  };
  const [map, setMap] = React.useState<any>(null);
  const [center, setCenter] = useState({
    latitude: 12.36566,
    longitude: -1.533388,
    description: '',
    name: '',
  })

  const onLoad = React.useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const { $get, $post } = apiHandler();
  const [shops, setShops] = useState<any[]>([]);
  const [contactForm, setContactForm] = useState({});

  const onChange = (evt: any) => {
    const data: any = { ...contactForm };
    data[evt.target.name] = evt.target.value;
    setContactForm(data);
  };

  const onSubmit = () => {
    // console.log(contactForm);
    $post(contactForm, "/contact");
  };

  const loadShops = async () => {
    const data: any = await $get("/shop");
    // console.log(data);
    if (data && data.shops) setShops(data.shops);
  };

  useEffect(() => {
    loadShops();
  }, []);

  return (
    <section id="contact" className="w-full  py-20 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
        <div className="w-full h-full bg-gray-400">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={6}
              center={{ lat: center.latitude, lng: center.longitude }}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {shops.map((shop) => (
                <MarkerF
                  key={shop.id}
                  position={{
                    lat: shop.address.latitude,
                    lng: shop.address.longitude,
                  }}
                />
              ))}
            </GoogleMap>
          ) : (
            <div className="bg-gray-500" style={mapStyles}></div>
          )}
        </div>
        <div className="space-y-4 bg-white shadow p-8 rounded-lg">
          <div className="text-center space-y-2">
            <h3 className="font-bold text-2xl">
              Voulez-vous devenir notre partenaire?
            </h3>
            <h4 className="text-primary">Laissez-nous un message</h4>
          </div>

          <div className="space-y-2">
            <label className="font-bold">Nom:</label>{" "}
            <div className="w-full">
              <input
                onChange={onChange}
                name="name"
                placeholder="Entrez votre nom"
                className="py-2 px-4 outline-none border-2 w-full rounded "
                type="text"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label className="font-bold">Email:</label>{" "}
            <div className="w-full">
              <input
                onChange={onChange}
                name="email"
                placeholder="Entrez votre email"
                className="py-2 px-4 outline-none border-2 w-full rounded"
                type="email"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label className="font-bold">Contact:</label>{" "}
            <div className="w-full">
              <input
                onChange={onChange}
                name="contact"
                placeholder="Entrez votre contact"
                className="py-2 px-4 outline-none border-2 w-full rounded"
                type="tel"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label className="font-bold">Compagnie:</label>{" "}
            <div className="w-full">
              <input
                onChange={onChange}
                name="company"
                placeholder="Entrez le nom de votre compagnie"
                className="py-2 px-4 outline-none border-2 w-full rounded "
                type="text"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label className="font-bold">Message:</label>{" "}
            <div className="w-full">
              <textarea
                placeholder="Ecrivez votre message ici..."
                onChange={onChange}
                name="message"
                rows={5}
                className="py-2 px-4 outline-none border-2 w-full rounde"
              ></textarea>
            </div>
          </div>
          <div className="flex justify-end py-4">
            <button
              onClick={onSubmit}
              className="bg-primary px-14 py-4 rounded text-white"
            >
              Envoyer
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
function $post() {
  throw new Error("Function not implemented.");
}
